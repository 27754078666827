<template>
  <div class="relative">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        <router-link :to="{ name: 'beasiswa' }">
          <ArrowLeftCircleIcon></ArrowLeftCircleIcon>
        </router-link>
        Tambah Beasiswa Mahasiswa
      </h2>
    </div>

    <div class="intro-y box p-5 mt-5">
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2">
        <div class="mb-2">
          <label class="form-label">NPM</label>
          <AutoComplete
            v-model="selectedMahasiswa"
            :suggestions="filteredMahasiswa"
            field="label"
            force-selection
            placeholder="Ketikkan Nama atau NPM Mahasiswa"
            class="w-full remake"
            @focus="loadDataMahasiswa"
            @complete="searchMahasiswa($event)"
          >
            <template #item="slotProps">
              <div class="item">
                <div>
                  <strong> {{ slotProps.item?.nama }}</strong>
                  {{ slotProps.item?.npm }}
                </div>
              </div>
            </template>
          </AutoComplete>
        </div>

        <div class="mb-2">
          <label class="form-label">Tahun Akademik</label>
          <select v-model="smt" class="form-select">
            <option
              v-for="smtr in semester"
              :key="smtr.id"
              :value="smtr.value"
              :selected="smtr.selected"
            >
              {{ smtr.text }}
            </option>
          </select>
          <span
            v-if="
              messageValidator?.thnakademik &&
              messageValidator?.thnakademik.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.thnakademik[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Jenis Biaya</label>
          <TailSelect
            v-model="jenis"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listJenis"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>
      </div>
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2 mt-3">
        <div class="mb-2">
          <label class="form-label">Jenis Beasiswa (Untuk PDDIKTI)</label>
          <TailSelect
            v-model="id_jns_beasiswa"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listJenisBeasiswa"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="
              messageValidator?.id_jns_beasiswa &&
              messageValidator?.id_jns_beasiswa.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.id_jns_beasiswa[0] }}</span
          >
        </div>

        <div class="mb-2">
          <label class="form-label">Pembiayaan (Untuk PDDIKTI)</label>
          <TailSelect
            v-model="id_pembiayaan"
            :options="{
              search: true,
              classNames: 'w-full',
              deselect: false,
            }"
          >
            <option
              v-for="item in listJenisPembiayaan"
              :key="item.id"
              :value="item.value"
              :selected="item.selected"
            >
              {{ item.text }}
            </option>
          </TailSelect>
          <span
            v-if="
              messageValidator?.id_pembiayaan &&
              messageValidator?.id_pembiayaan.length > 0
            "
            class="text-red-600"
            >{{ messageValidator?.id_pembiayaan[0] }}</span
          >
        </div>
      </div>
      <div class="grid sm:grid-cols-3 grid-cols-1 gap-2 mt-3">
        <div class="mr-2">
          <label class="form-label">Nominal</label>
          <input
            v-model="nominal"
            type="number"
            class="form-control border-green-500"
            rows="4"
            @keyup="formatRupiah(nominal)"
          />
          <div
            v-if="
              messageValidator?.nominal && messageValidator?.nominal.length > 0
            "
            class="text-red-600"
          >
            {{ messageValidator?.nominal[0] }}
          </div>
          <div v-else class="text-green-800 font-medium mt-1">
            {{ rupiahNominal }}
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label">Akun</label>
          <AutoComplete
            v-model="selectedAkun"
            :suggestions="filteredAkun"
            field="label"
            force-selection
            placeholder="Ketikkan Nama Akun atau Kode Akun"
            class="w-full remake"
            @focus="loadDataAkun"
            @complete="searchAkun($event)"
          >
            <template #item="slotProps">
              <div class="item">
                <div>
                  <strong> {{ slotProps.item?.label }}</strong>
                  {{ slotProps.item?.kd_account }}
                </div>
              </div>
            </template>
          </AutoComplete>
          <span
            v-if="messageValidator?.jenis && messageValidator?.jenis.length > 0"
            class="text-red-600"
            >{{ messageValidator?.jenis[0] }}</span
          >
        </div>
      </div>

      <div class="mt-3">
        <label class="form-label">Keterangan</label>
        <textarea v-model="keterangan" class="form-control" rows="4"></textarea>
      </div>

      <div class="my-3">
        <button
          v-if="selectedMahasiswa?.id_reg_pd"
          class="btn btn-primary w-full"
          @click="simpanBeasiswa"
        >
          SIMPAN
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { HTTPKEU } from "../../../services/http";
import { mahasiswa, master, keuangan } from "@/services/models";
import router from "@/router";
import { userdata } from "@/utils/userdata";

export default defineComponent({
  data() {
    return {
      listMahasiswa: null,
      filteredMahasiswa: null,
      selectedMahasiswa: [],
      semester: [],
      smt: "",
      listJenis: [],
      jenis: "",
      listJenisBeasiswa: [],
      id_jns_beasiswa: 3,
      listJenisPembiayaan: [],
      id_pembiayaan: 3,
      listAkun: null,
      filteredAkun: null,
      selectedAkun: [],
      keterangan: "",
      nominal: "",
      messageValidator: [],
      rupiahNominal: "",
    };
  },
  mounted() {
    this.fetchSemester();
    this.jenislist();
    this.jenisBeasiswalist();
    this.jenisPembiayaanlist();
  },
  methods: {
    formatRupiah(nominal) {
      if (nominal) {
        let rupiahLocal = Intl.NumberFormat("id-ID");
        this.rupiahNominal = "Rp " + rupiahLocal.format(nominal) + ",00";
      } else {
        this.rupiahNominal = "";
      }
    },
    searchMahasiswa(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredMahasiswa = [...this.listMahasiswa];
        } else {
          mahasiswa.RegistasiPD.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listMahasiswa = result.data.data.map((mhs) => {
              return {
                id_reg_pd: mhs.id_reg_pd,
                id_pd: mhs.peserta_didik?.id_pd,
                nama: mhs.peserta_didik?.nm_pd,
                npm: mhs.nipd,
                mhs: mhs,
                label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
              };
            });
            this.filteredMahasiswa = [...this.listMahasiswa];
          });
        }
      }, 100);
    },
    loadDataMahasiswa() {
      if (!userdata.isAuthenticated())
        mahasiswa.RegistasiPD.searchData({}).then((result) => {
          this.listMahasiswa = result?.data?.data?.map((mhs) => {
            return {
              id_reg_pd: mhs.id_reg_pd,
              id_pd: mhs.peserta_didik?.id_pd,
              nama: mhs.peserta_didik?.nm_pd,
              npm: mhs.nipd,
              mhs: mhs,
              label: mhs.peserta_didik?.nm_pd + " (" + mhs.nipd + ")",
            };
          });
        });
    },
    fetchSemester() {
      let that = this;
      this.semester.push({
        id: "",
        value: "",
        text: "Pilih Semester",
        selected: false,
      });
      master.Semester.getAllActive().then((result) => {
        if (result.status === 200) {
          result.data.data.forEach((item) => {
            let newoption = {
              id: item.id_smt,
              value: item.id_smt,
              text: item.nm_smt,
              id_smt: item.id_smt,
              nm_smt: item.nm_smt,
              smt: item.smt,
              id_thn_ajaran: item.id_thn_ajaran,
              thn_ajaran: item.thn_ajaran,
              selected: that.current?.id_smt === item.id_smt,
            };

            that.semester.push(newoption);
          });
        }
      });
    },
    jenislist() {
      HTTPKEU.get("list/jenisbiaya").then((res) => {
        this.listJenis = [];
        let opt = {
          id: 0,
          value: "",
          text: "Pilih Jenis Pembiayaan",
          selected: true,
        };
        this.listJenis.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id,
            value: item.id,
            text: `${item.keterangan}`,
            selected: false,
          };
          this.listJenis.push(opt);
        });
      });
    },
    jenisBeasiswalist() {
      HTTPKEU.get("list/jenisbeasiswa").then((res) => {
        this.listJenisBeasiswa = [];
        let opt = {
          id: 0,
          value: "",
          text: "Pilih Jenis Beasiswa",
          selected: true,
        };
        this.listJenisBeasiswa.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id_jns_beasiswa,
            value: item.id_jns_beasiswa,
            text: `${item.nm_jns_beasiswa} (${item.id_jns_beasiswa})`,
            selected: item.id_jns_beasiswa == this.id_jns_beasiswa,
          };
          this.listJenisBeasiswa.push(opt);
        });
      });
    },
    jenisPembiayaanlist() {
      HTTPKEU.get("list/jenispembiayaan").then((res) => {
        this.listJenisPembiayaan = [];
        let opt = {
          id: 0,
          value: "",
          text: "Pilih Jenis Pembiayaan",
          selected: true,
        };
        this.listJenisPembiayaan.push(opt);

        res.data.forEach((item) => {
          const opt = {
            id: item.id_pembiayaan,
            value: item.id_pembiayaan,
            text: `${item.nm_pembiayaan}`,
            selected: item.id_pembiayaan == this.id_pembiayaan,
          };
          this.listJenisPembiayaan.push(opt);
        });
      });
    },
    searchAkun(event) {
      setTimeout(() => {
        if (!event.query.length) {
          this.filteredAkun = [...this.listAkun];
        } else {
          keuangan.Akun.searchData({
            params: {
              q: event.query,
            },
          }).then((result) => {
            this.listAkun = result.data.data.map((act) => {
              return {
                kd_account: act.kd_account,
                account: act.account,
                label: act.account + " (" + act.kd_account + ")",
              };
            });
            this.filteredAkun = [...this.listAkun];
          });
        }
      }, 100);
    },
    loadDataAkun() {
      if (!userdata.isAuthenticated())
        keuangan.Akun.searchData({}).then((result) => {
          this.listAkun = result?.data?.data?.map((acc) => {
            return {
              kd_account: acc.kd_account,
              accound: acc.account,
              label: acc.account + " (" + acc.kd_account + ")",
            };
          });
        });
    },
    simpanBeasiswa() {
      let val = {
        id_reg_pd: this.selectedMahasiswa.id_reg_pd,
        thnakademik: this.smt,
        jenis: this.jenis,
        id_jns_beasiswa: this.id_jns_beasiswa,
        id_pembiayaan: this.id_pembiayaan,
        keterangan: this.keterangan,
        nominal: this.nominal,
        akun: this.selectedAkun.kd_account,
      };
      keuangan.Beasiswa.insertData(val).then((res) => {
        if (res.data.code === 200) {
          router.push({
            name: "beasiswa",
          });
        } else {
          this.messageValidator = res.data.message;
        }
      });
    },
  },
});
</script>

<style scope>
.p-autocomplete > input {
  width: 100% !important;
}
.remake {
  padding: 0 !important;
}
</style>
